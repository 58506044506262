




















































































import Vue from 'vue';
import { getAuth, signOut } from 'firebase/auth';

export default Vue.extend({
  name: 'App',

  data: () => ({
    drawer: false,
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    async logout() {
      await this.$store.dispatch('destroyUsers');
      await this.$store.dispatch('destroyBooks');
      await this.$store.dispatch('destroyCards');
      await this.$store.dispatch('destroyArtists');

      const auth = getAuth();

      if (auth) {
        signOut(auth).then(() => {
          console.log('firebase.auth().signOut() success'); // eslint-disable-line no-console

          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.$router.replace({ name: 'Login' }, () => {});
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.replace({ name: 'Login' }, () => {});
      }
    },
  },
});
