



















/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component,
} from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import PageHeader from '@/components/PageHeader.vue';
import Cards from '@/components/Cards.vue';
import NewCardDialog from '@/components/NewCardDialog.vue';

@Component({
  components: {
    Loading,
    PageHeader,
    Cards,
    NewCardDialog,
  },
})
export default class Tessere extends Vue {
  loading = true
  dialog = false

  breadcrumbs = [
    {
      text: 'Tessere',
      exact: true,
      to: { name: 'Tessere' },
    },
  ]
}
