














































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Artist } from '@/types';

@Component
export default class EditArtistDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly artist!: Artist | null

  dialog = false
  valid = false
  loading = false

  updatedArtist = {
    id: '',
    name: '',
  } as Artist

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false && this.artist) {
      this.updatedArtist.id = this.artist.id;
      this.updatedArtist.name = this.artist.name;
    }
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  onCancel() {
    this.dialog = false;
  }

  async onUpdate() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate() && this.artist) {
      this.loading = true;

      await this.$store.dispatch('updateArtist', {
        artist: {
          id: this.artist.id,
          ...this.updatedArtist,
        },
      });

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
