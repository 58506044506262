





















































































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Book } from '@/types';
import NewBookDialog from '@/components/NewBookDialog.vue';
import DeleteBookDialog from '@/components/DeleteBookDialog.vue';
import EditBookDialog from '@/components/EditBookDialog.vue';

@Component({
  components: {
    NewBookDialog,
    DeleteBookDialog,
    EditBookDialog,
  },
})
export default class Books extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean

  newDialog = false
  deleteDialog = false
  editDialog = false
  editCredentialsDialog = false

  selected = null as Book | null

  headers = [
    { text: 'ISBN', value: 'isbn', sortable: false },
    { text: 'Titolo', value: 'title', sortable: false },
    { text: 'Autore', value: 'author', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  filters = {
    search: '',
    archived: false,
  }

  get books(): Book[] {
    return this.$store.getters.books;
  }

  get isSearchBlank() {
    return (!this.filters.search || /^\s*$/.test(this.filters.search));
  }

  get filteredBooks() {
    if (this.isSearchBlank) return (this as any).books;

    return (this as any).books
      ? (this as any).books.filter((b: Book) => {
        if (!b) return false;

        const bookStrings = [
          b.isbn,
          b.title,
          b.author,
        ];

        let found = false;
        bookStrings.forEach((str) => {
          if (typeof str === 'string' && typeof this.filters.search === 'string'
            && str.toLowerCase().includes(this.filters.search.toLowerCase())) found = true;
        });

        return found;
      })
      : [];
  }

  async created() {
    console.log('Books: created()'); // eslint-disable-line no-console

    if (Array.isArray(this.books)) {
      this.$emit('ready');
    }
  }

  @Watch('books')
  onValueChanged(val: Book[] | null, oldVal: Book[] | null) {
    console.log('Books: watch.value() val =', val); // eslint-disable-line no-console
    console.log('Books: watch.value() oldVal =', oldVal); // eslint-disable-line no-console
  }

  onDelete(book: Book) {
    this.selected = book;
    this.deleteDialog = true;
  }

  onEdit(book: Book) {
    this.selected = book;
    this.editDialog = true;
  }
}
