/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import {
  getFirestore, collection, addDoc, onSnapshot,
  QuerySnapshot, DocumentData, doc, getDoc, deleteDoc, updateDoc,
} from 'firebase/firestore';
import { getApp } from 'firebase/app';
import {
  getStorage, ref, deleteObject,
} from 'firebase/storage';
import { Card } from '@/types';

@Module
export default class Cards extends VuexModule {
  data: Card[] = []

  listener: (() => void) | null = null

  get cards(): Card[] {
    return Object.values(this.data);
  }

  @Mutation
  setCards({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    const data: Card[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Card);
      });
    }

    this.data = data;
  }

  @Mutation
  setCardsListener({ listener }: { listener: (() => void) | null }) {
    this.listener = listener;
  }

  @Action
  initCards() {
    console.log('actions:initCards'); // eslint-disable-line no-console
    return this.context.dispatch('updateCards');
  }

  @Action
  destroyCards() {
    console.log('actions:destroyCards'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();
  }

  @Action({ rawError: true })
  async updateCards() {
    console.log('actions:updateCards'); // eslint-disable-line no-console

    const db = getFirestore();

    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'cards');
    const listener = onSnapshot(collectionRef, (snapshot) => {
      console.log('onSnapshot (cards)'); // eslint-disable-line no-console

      this.context.commit('setCards', { snapshot });
    });

    this.context.commit('setCardsListener', { listener });
  }

  @Action({ rawError: true })
  async addCard({ card }: { card: Card }) {
    console.log('actions:addCard'); // eslint-disable-line no-console

    const db = getFirestore();

    const collectionRef = collection(db, 'cards');

    const newCard = {
      ...card,
    } as Card;

    return addDoc(collectionRef, newCard);
  }

  @Action({ rawError: true })
  async updateCard({ card }: { card: Card }) {
    console.log('actions:updateCard'); // eslint-disable-line no-console

    const db = getFirestore();

    // Separa 'id' dal resto
    const { id, ...data } = card;

    const docRef = doc(db, `/cards/${id}`);

    return updateDoc(docRef, {
      ...data,
    });
  }

  @Action
  async deleteCard({ card }: { card: Card }) {
    console.log('actions:deleteCard'); // eslint-disable-line no-console

    const { id } = card;

    // Recupera il riferimento all'utente
    const db = getFirestore();
    const docRef = doc(db, `/cards/${id}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const snapDoc = await getDoc(docRef);
    const docData = snapDoc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove il file dallo storage
    if (imageName) {
      const firebaseApp = getApp();
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage);
      const imageRef = ref(storageRef, imageName);
      await deleteObject(imageRef);
    }

    // Rimuove l'utente
    return deleteDoc(docRef);
  }

  @Action
  async getCardById(id: string): Promise<Card | null> {
    console.log('actions:getCardById'); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/cards/${id}`);

    const cardDoc = await getDoc(docRef);

    if (!cardDoc.exists) return null;

    return {
      id: cardDoc.id,
      ...cardDoc.data(),
    } as Card;
  }
}
