













import Vue from 'vue';

export default Vue.extend({
  props: ['value'],

  name: 'Loading' as string,

  data: () => ({
    loading: true,
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    loading(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },
});
