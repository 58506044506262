




















import Vue from 'vue';

export default Vue.extend({
  name: 'PageHeader' as string,

  props: ['breadcrumbs', 'icon'],

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },
});
