




























































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Artist } from '@/types';
import NewArtistDialog from '@/components/NewArtistDialog.vue';
import DeleteArtistDialog from '@/components/DeleteArtistDialog.vue';
import EditArtistDialog from '@/components/EditArtistDialog.vue';

@Component({
  components: {
    NewArtistDialog,
    DeleteArtistDialog,
    EditArtistDialog,
  },
})
export default class Artists extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean

  newDialog = false
  deleteDialog = false
  editDialog = false
  editCredentialsDialog = false

  selected = null as Artist | null

  headers = [
    { text: 'Nome', value: 'name', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  filters = {
    search: '',
    archived: false,
  }

  get artists(): Artist[] {
    return this.$store.getters.artists;
  }

  get isSearchBlank() {
    return (!this.filters.search || /^\s*$/.test(this.filters.search));
  }

  get filteredArtists() {
    if (this.isSearchBlank) return (this as any).artists;

    return (this as any).artists
      ? (this as any).artists.filter((b: Artist) => {
        if (!b) return false;

        const artistStrings = [
          b.name,
        ];

        let found = false;
        artistStrings.forEach((str) => {
          if (typeof str === 'string' && typeof this.filters.search === 'string'
            && str.toLowerCase().includes(this.filters.search.toLowerCase())) found = true;
        });

        return found;
      })
      : [];
  }

  async created() {
    console.log('Artists: created()'); // eslint-disable-line no-console

    if (Array.isArray(this.artists)) {
      this.$emit('ready');
    }
  }

  @Watch('artists')
  onValueChanged(val: Artist[] | null, oldVal: Artist[] | null) {
    console.log('Artists: watch.value() val =', val); // eslint-disable-line no-console
    console.log('Artists: watch.value() oldVal =', oldVal); // eslint-disable-line no-console
  }

  onDelete(artist: Artist) {
    this.selected = artist;
    this.deleteDialog = true;
  }

  onEdit(artist: Artist) {
    this.selected = artist;
    this.editDialog = true;
  }
}
