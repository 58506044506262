



















/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component,
} from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import PageHeader from '@/components/PageHeader.vue';
import Artists from '@/components/Artists.vue';
import NewArtistDialog from '@/components/NewArtistDialog.vue';

@Component({
  components: {
    Loading,
    PageHeader,
    Artists,
    NewArtistDialog,
  },
})
export default class Artisti extends Vue {
  loading = true
  dialog = false

  breadcrumbs = [
    {
      text: 'Artisti',
      exact: true,
      to: { name: 'Artisti' },
    },
  ]
}
