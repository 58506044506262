import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { getAuth } from 'firebase/auth';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Libri from '@/views/Libri.vue';
import Utenti from '@/views/Utenti.vue';
import Tessere from '@/views/Tessere.vue';
import Artisti from '@/views/Artisti.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/admin',
    redirect: '/admin/libri',
  },
  {
    path: '/admin/libri',
    name: 'Libri',
    component: Libri,
  },
  {
    path: '/admin/tessere',
    name: 'Tessere',
    component: Tessere,
  },
  {
    path: '/admin/artisti',
    name: 'Artisti',
    component: Artisti,
  },
  {
    path: '/admin/utenti',
    name: 'Utenti',
    component: Utenti,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login'];
  const authRequired = !publicPages.includes(to.path);

  const auth = getAuth();
  const { currentUser } = auth;

  if (authRequired && !currentUser) {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    });
  }

  return next();
});

export default router;
