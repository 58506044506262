



































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { User } from '@/types';

@Component
export default class EditUserDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly user!: User | null

  dialog = false
  valid = false
  loading = false

  updatedUser = {
    firstName: '',
    lastName: '',
    isSuper: false,
  } as User

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false && this.user) {
      this.updatedUser.firstName = this.user.firstName;
      this.updatedUser.lastName = this.user.lastName;
      this.updatedUser.isSuper = this.user.isSuper;
    }
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  get currentUser() {
    return this.$store.getters.user;
  }

  get isSelf() {
    return this.currentUser && this.user && this.currentUser.uid === this.user.uid;
  }

  onCancel() {
    this.dialog = false;
  }

  async onUpdate() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate() && this.user) {
      this.loading = true;

      await this.$store.dispatch('updateUser', {
        user: {
          id: this.user.id,
          ...this.updatedUser,
        },
      });

      if (this.isSelf) {
        console.log('Stai modificando te stesso'); // eslint-disable-line no-console
        await this.$store.dispatch('refreshCurrentUser');
      }

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
