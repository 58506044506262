










































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { User } from '@/types';

@Component
export default class EditCredentialsDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly user!: User | null

  dialog = false
  valid = false
  loading = false

  updatedUser = {
    email: '',
    password: '',
  }
  passwordConfirmation = ''

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false && this.user && this.user.email) {
      this.updatedUser.email = this.user.email;
      this.updatedUser.password = '';
      this.passwordConfirmation = '';
    }
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  get emailRules() {
    return [
      (v: string) => !!v || "L'indirizzo email è richiesto",
      (v: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        .test(v) || "L'indirizzo email non è un indirizzo corretto",
    ];
  }

  get passwordRules() {
    return [
      (v: string) => !!v || 'La password è richiesta',
      (v: string) => v.length > 5 || 'La password deve essere lunga almeno 6 caratteri',
    ];
  }

  get passwordConfirmationRules() {
    const rules = [];
    rules.push((v: string) => !this.updatedUser.password || !!v || 'La password va ripetuta');
    rules.push((v: string) => (this.updatedUser.password && v === this.updatedUser.password) || 'La password non corrisponde');
    return rules;
  }

  get currentUser() {
    return this.$store.getters.user;
  }

  get isSelf() {
    return this.currentUser && this.user && this.currentUser.uid === this.user.uid;
  }

  onCancel() {
    this.dialog = false;
  }

  async onUpdate() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate() && this.user) {
      this.loading = true;

      await this.$store.dispatch('updateUserCredentials', {
        user: {
          uid: this.user.uid,
          ...this.updatedUser,
        },
      });

      if (this.isSelf) {
        console.log('Stai modificando te stesso'); // eslint-disable-line no-console

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.replace({ name: 'Login' }, () => {});
      }

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
