/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import {
  getFirestore, collection, addDoc, onSnapshot,
  QuerySnapshot, DocumentData, doc, getDoc, deleteDoc, updateDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { v4 as uuidv4 } from 'uuid';
import { User, CreateUserResult } from '@/types';

@Module
export default class Users extends VuexModule {
  data: User[] = []

  listener: (() => void) | null = null

  get users(): User[] {
    return Object.values(this.data);
  }

  @Mutation
  setUsers({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    const data: User[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as User);
      });
    }

    this.data = data;
  }

  @Mutation
  setUsersListener({ listener }: { listener: (() => void) | null }) {
    this.listener = listener;
  }

  @Action
  initUsers() {
    console.log('actions:initUsers'); // eslint-disable-line no-console
    return this.context.dispatch('updateUsers');
  }

  @Action
  destroyUsers() {
    console.log('actions:destroyUsers'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();
  }

  @Action({ rawError: true })
  async updateUsers() {
    console.log('actions:updateUsers'); // eslint-disable-line no-console

    const db = getFirestore();

    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'users');
    const listener = onSnapshot(collectionRef, (snapshot) => {
      console.log('onSnapshot (users)'); // eslint-disable-line no-console

      this.context.commit('setUsers', { snapshot });
    });

    this.context.commit('setUsersListener', { listener });
  }

  @Action({ rawError: true })
  async addUser({ user }: { user: User }) {
    console.log('actions:addUser'); // eslint-disable-line no-console

    const { email, password, ...data } = user;

    const functions = getFunctions();
    const createUser = httpsCallable(functions, 'createUser');
    const result = await createUser({ email, password });
    console.log('result =', result); // eslint-disable-line no-console

    const { uid } = result.data as CreateUserResult;

    const db = getFirestore();

    const collectionRef = collection(db, 'users');

    const newUser = {
      ...data,
      email,
      uid,
    } as User;

    return addDoc(collectionRef, newUser);
  }

  @Action({ rawError: true })
  async updateUser({ user }: { user: User }) {
    console.log('actions:updateUser'); // eslint-disable-line no-console

    const db = getFirestore();

    // Separa 'id' dal resto
    const { id, ...data } = user;

    const docRef = doc(db, `/users/${id}`);

    return updateDoc(docRef, {
      ...data,
    });
  }

  @Action({ rawError: true })
  async updateUserCredentials({ user }: { user: User }) {
    console.log('actions:updateUserCredentials'); // eslint-disable-line no-console

    if ('uid' in user && 'email' in user && 'password' in user) {
      const functions = getFunctions();
      const updateUser = httpsCallable(functions, 'updateUser');
      const result = await updateUser({
        uid: user.uid,
        email: user.email,
        password: user.password,
      });
      console.log('result =', result); // eslint-disable-line no-console
    }

    return Promise.resolve();
  }

  @Action
  async deleteUser({ user }: { user: User }) {
    console.log('actions:deleteUser'); // eslint-disable-line no-console

    const { id, uid } = user;

    const functions = getFunctions();
    const deleteUser = httpsCallable(functions, 'deleteUser');
    const result = await deleteUser({ uid });
    console.log('result =', result); // eslint-disable-line no-console

    // Recupera il riferimento all'utente
    const db = getFirestore();
    const docRef = doc(db, `/users/${id}`);

    // Rimuove l'utente
    return deleteDoc(docRef);
  }

  @Action
  async getUserById(id: string): Promise<User | null> {
    console.log('actions:getUserById'); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/users/${id}`);

    const userDoc = await getDoc(docRef);

    if (!userDoc.exists) return null;

    return {
      id: userDoc.id,
      ...userDoc.data(),
    } as User;
  }
}
