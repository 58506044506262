var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-books"},[(_vm.books && _vm.books.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"Cerca...","append-icon":"mdi-magnify","clearable":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{staticClass:"archived-switch-continer",attrs:{"cols":"12","md":"9"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"color":"orange","label":"Mostra archiviati"},model:{value:(_vm.filters.archived),callback:function ($$v) {_vm.$set(_vm.filters, "archived", $$v)},expression:"filters.archived"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredBooks,"item-key":"id","items-per-page":20,"sort-by":"name","sort-desc":false,"footer-props":{
            itemsPerPageOptions: [10, 20, 30, -1],
          }},scopedSlots:_vm._u([{key:"item.isbn",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.isbn)+" ")]}},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.author",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.author)+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.archived)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"orange"}},[_vm._v(" mdi-archive ")]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary"},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1)],1)],1):(_vm.books)?_c('v-alert',{attrs:{"text":"","prominent":"","type":"success","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Al momento non è presente nessun libro. ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.newDialog = true}}},[_vm._v("Aggiungi un libro")])],1)],1)],1):_vm._e(),_c('NewBookDialog',{model:{value:(_vm.newDialog),callback:function ($$v) {_vm.newDialog=$$v},expression:"newDialog"}}),_c('DeleteBookDialog',{attrs:{"book":_vm.selected},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_c('EditBookDialog',{attrs:{"book":_vm.selected},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }