





















































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { User } from '@/types';

@Component
export default class NewUserDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean

  dialog = false
  valid = false
  loading = false

  user = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isSuper: false,
  } as User

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false) {
      this.user.firstName = '';
      this.user.lastName = '';
      this.user.email = '';
      this.user.password = '';
      this.user.isSuper = false;
    }
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  get emailRules() {
    return [
      (v: string) => !!v || "L'indirizzo email è richiesto",
      (v: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        .test(v) || "L'indirizzo email non è un indirizzo corretto",
    ];
  }

  get passwordRules() {
    return [
      (v: string) => !!v || 'La password è richiesta',
      (v: string) => v.length > 5 || 'La password deve essere lunga almeno 6 caratteri',
    ];
  }

  onCancel() {
    this.dialog = false;
  }

  async onAdd() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate()) {
      this.loading = true;

      await this.$store.dispatch('addUser', {
        user: this.user,
      });

      this.loading = false;
      this.dialog = false;
    }
  }
}
