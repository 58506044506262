




























































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Card } from '@/types';
import NewCardDialog from '@/components/NewCardDialog.vue';
import DeleteCardDialog from '@/components/DeleteCardDialog.vue';
import EditCardDialog from '@/components/EditCardDialog.vue';

@Component({
  components: {
    NewCardDialog,
    DeleteCardDialog,
    EditCardDialog,
  },
})
export default class Cards extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean

  newDialog = false
  deleteDialog = false
  editDialog = false
  editCredentialsDialog = false

  selected = null as Card | null

  headers = [
    { text: 'Nome', value: 'name', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  filters = {
    search: '',
    archived: false,
  }

  get cards(): Card[] {
    return this.$store.getters.cards;
  }

  get isSearchBlank() {
    return (!this.filters.search || /^\s*$/.test(this.filters.search));
  }

  get filteredCards() {
    if (this.isSearchBlank) return (this as any).cards;

    return (this as any).cards
      ? (this as any).cards.filter((b: Card) => {
        if (!b) return false;

        const cardStrings = [
          b.name,
        ];

        let found = false;
        cardStrings.forEach((str) => {
          if (typeof str === 'string' && typeof this.filters.search === 'string'
            && str.toLowerCase().includes(this.filters.search.toLowerCase())) found = true;
        });

        return found;
      })
      : [];
  }

  async created() {
    console.log('Cards: created()'); // eslint-disable-line no-console

    if (Array.isArray(this.cards)) {
      this.$emit('ready');
    }
  }

  @Watch('cards')
  onValueChanged(val: Card[] | null, oldVal: Card[] | null) {
    console.log('Cards: watch.value() val =', val); // eslint-disable-line no-console
    console.log('Cards: watch.value() oldVal =', oldVal); // eslint-disable-line no-console
  }

  onDelete(card: Card) {
    this.selected = card;
    this.deleteDialog = true;
  }

  onEdit(card: Card) {
    this.selected = card;
    this.editDialog = true;
  }
}
