/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import {
  getFirestore, collection, addDoc, onSnapshot,
  QuerySnapshot, DocumentData, doc, getDoc, deleteDoc, updateDoc,
} from 'firebase/firestore';
import { getApp } from 'firebase/app';
import {
  getStorage, ref, deleteObject,
} from 'firebase/storage';
import { Artist } from '@/types';

@Module
export default class Artists extends VuexModule {
  data: Artist[] = []

  listener: (() => void) | null = null

  get artists(): Artist[] {
    return Object.values(this.data);
  }

  @Mutation
  setArtists({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    const data: Artist[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Artist);
      });
    }

    this.data = data;
  }

  @Mutation
  setArtistsListener({ listener }: { listener: (() => void) | null }) {
    this.listener = listener;
  }

  @Action
  initArtists() {
    console.log('actions:initArtists'); // eslint-disable-line no-console
    return this.context.dispatch('updateArtists');
  }

  @Action
  destroyArtists() {
    console.log('actions:destroyArtists'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();
  }

  @Action({ rawError: true })
  async updateArtists() {
    console.log('actions:updateArtists'); // eslint-disable-line no-console

    const db = getFirestore();

    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'artists');
    const listener = onSnapshot(collectionRef, (snapshot) => {
      console.log('onSnapshot (artists)'); // eslint-disable-line no-console

      this.context.commit('setArtists', { snapshot });
    });

    this.context.commit('setArtistsListener', { listener });
  }

  @Action({ rawError: true })
  async addArtist({ artist }: { artist: Artist }) {
    console.log('actions:addArtist'); // eslint-disable-line no-console

    const db = getFirestore();

    const collectionRef = collection(db, 'artists');

    const newArtist = {
      ...artist,
    } as Artist;

    return addDoc(collectionRef, newArtist);
  }

  @Action({ rawError: true })
  async updateArtist({ artist }: { artist: Artist }) {
    console.log('actions:updateArtist'); // eslint-disable-line no-console

    const db = getFirestore();

    // Separa 'id' dal resto
    const { id, ...data } = artist;

    const docRef = doc(db, `/artists/${id}`);

    return updateDoc(docRef, {
      ...data,
    });
  }

  @Action
  async deleteArtist({ artist }: { artist: Artist }) {
    console.log('actions:deleteArtist'); // eslint-disable-line no-console

    const { id } = artist;

    // Recupera il riferimento all'utente
    const db = getFirestore();
    const docRef = doc(db, `/artists/${id}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const snapDoc = await getDoc(docRef);
    const docData = snapDoc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove il file dallo storage
    if (imageName) {
      const firebaseApp = getApp();
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage);
      const imageRef = ref(storageRef, imageName);
      await deleteObject(imageRef);
    }

    // Rimuove l'utente
    return deleteDoc(docRef);
  }

  @Action
  async getArtistById(id: string): Promise<Artist | null> {
    console.log('actions:getArtistById'); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/artists/${id}`);

    const artistDoc = await getDoc(docRef);

    if (!artistDoc.exists) return null;

    return {
      id: artistDoc.id,
      ...artistDoc.data(),
    } as Artist;
  }
}
