


















































































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { User } from '@/types';
import NewUserDialog from '@/components/NewUserDialog.vue';
import DeleteUserDialog from '@/components/DeleteUserDialog.vue';
import EditUserDialog from '@/components/EditUserDialog.vue';
import EditCredentialsDialog from '@/components/EditCredentialsDialog.vue';

@Component({
  components: {
    NewUserDialog,
    DeleteUserDialog,
    EditUserDialog,
    EditCredentialsDialog,
  },
})
export default class Users extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean

  newDialog = false
  deleteDialog = false
  editDialog = false
  editCredentialsDialog = false

  selected = null as User | null

  headers = [
    { text: 'Nome', value: 'name', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: '', value: 'isSuper', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  filters = {
    search: '',
    archived: false,
  }

  get users(): User[] {
    return this.$store.getters.users;
  }

  get isSearchBlank() {
    return (!this.filters.search || /^\s*$/.test(this.filters.search));
  }

  get filteredUsers() {
    if (this.isSearchBlank) return (this as any).users;

    return (this as any).users
      ? (this as any).users.filter((u: User) => {
        if (!u) return false;

        const userStrings = [
          u.firstName,
          u.lastName,
          `${u.firstName} ${u.lastName}`,
          `${u.lastName} ${u.firstName}`,
        ];

        let found = false;
        userStrings.forEach((str) => {
          if (typeof str === 'string' && typeof this.filters.search === 'string'
            && str.toLowerCase().includes(this.filters.search.toLowerCase())) found = true;
        });

        return found;
      })
      : [];
  }

  async created() {
    console.log('Users: created()'); // eslint-disable-line no-console

    if (Array.isArray(this.users)) {
      this.$emit('ready');
    }
  }

  @Watch('users')
  onValueChanged(val: User[] | null, oldVal: User[] | null) {
    console.log('Users: watch.value() val =', val); // eslint-disable-line no-console
    console.log('Users: watch.value() oldVal =', oldVal); // eslint-disable-line no-console
  }

  fullName(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  onDelete(user: User) {
    this.selected = user;
    this.deleteDialog = true;
  }

  onEdit(user: User) {
    this.selected = user;
    this.editDialog = true;
  }

  onEditCredentials(user: User) {
    this.selected = user;
    this.editCredentialsDialog = true;
  }
}
