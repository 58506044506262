/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import {
  getFirestore, collection, addDoc, onSnapshot,
  QuerySnapshot, DocumentData, doc, getDoc, deleteDoc, updateDoc,
} from 'firebase/firestore';
import { getApp } from 'firebase/app';
import {
  getStorage, ref, deleteObject, uploadBytesResumable, getDownloadURL,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { Book } from '@/types';

@Module
export default class Books extends VuexModule {
  data: Book[] = []

  listener: (() => void) | null = null

  get books(): Book[] {
    return Object.values(this.data);
  }

  @Mutation
  setBooks({ snapshot }: {
    snapshot: QuerySnapshot<DocumentData>;
  }) {
    const data: Book[] = [];

    if (snapshot) {
      snapshot.forEach((docSnap) => {
        const docData = docSnap.data();

        data.push({
          id: docSnap.id,
          ...docData,
        } as Book);
      });
    }

    this.data = data;
  }

  @Mutation
  setBooksListener({ listener }: { listener: (() => void) | null }) {
    this.listener = listener;
  }

  @Action
  initBooks() {
    console.log('actions:initBooks'); // eslint-disable-line no-console
    return this.context.dispatch('updateBooks');
  }

  @Action
  destroyBooks() {
    console.log('actions:destroyBooks'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();
  }

  @Action({ rawError: true })
  async updateBooks() {
    console.log('actions:updateBooks'); // eslint-disable-line no-console

    const db = getFirestore();

    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();

    // Recupera la collection con l'elenco
    const collectionRef = collection(db, 'books');
    const listener = onSnapshot(collectionRef, (snapshot) => {
      console.log('onSnapshot (books)'); // eslint-disable-line no-console

      this.context.commit('setBooks', { snapshot });
    });

    this.context.commit('setBooksListener', { listener });
  }

  @Action({ rawError: true })
  async addBook({ book }: { book: Book }) {
    console.log('actions:addBook'); // eslint-disable-line no-console

    const db = getFirestore();

    const collectionRef = collection(db, 'books');

    const newBook = {
      ...book,
    } as Book;

    return addDoc(collectionRef, newBook);
  }

  @Action({ rawError: true })
  async updateBook({ book }: { book: Book }) {
    console.log('actions:updateBook'); // eslint-disable-line no-console

    const db = getFirestore();

    // Separa 'id' dal resto
    const { id, ...data } = book;

    const docRef = doc(db, `/books/${id}`);

    return updateDoc(docRef, {
      ...data,
    });
  }

  @Action
  async deleteBook({ book }: { book: Book }) {
    console.log('actions:deleteBook'); // eslint-disable-line no-console

    const { id } = book;

    // Recupera il riferimento all'utente
    const db = getFirestore();
    const docRef = doc(db, `/books/${id}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const snapDoc = await getDoc(docRef);
    const docData = snapDoc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove il file dallo storage
    if (imageName) {
      const firebaseApp = getApp();
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage);
      const imageRef = ref(storageRef, imageName);
      await deleteObject(imageRef);
    }

    // Rimuove l'utente
    return deleteDoc(docRef);
  }

  @Action
  async getBookById(id: string): Promise<Book | null> {
    console.log('actions:getBookById'); // eslint-disable-line no-console

    const db = getFirestore();

    const docRef = doc(db, `/books/${id}`);

    const bookDoc = await getDoc(docRef);

    if (!bookDoc.exists) return null;

    return {
      id: bookDoc.id,
      ...bookDoc.data(),
    } as Book;
  }

  @Action
  async updateBookImage({ bookId, file }: { bookId: string; file: File }) {
    console.log('actions:updateBookImage'); // eslint-disable-line no-console

    const db = getFirestore();

    // Se stiamo modificando un libro esistente...
    if (bookId) {
      // ... elimina l'eventuale immagine precedente

      // Recupera il nome dell'immagine da eliminare dallo storage
      const docRef = doc(db, `/books/${bookId}`);
      const bookDoc = await getDoc(docRef);
      const docData = bookDoc.data();
      const imageName = docData ? docData.imageName : null;

      // Rimuove il file dallo storage (se presente)
      if (imageName) {
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp);
        const storageRef = ref(storage);
        const imageRef = ref(storageRef, imageName);
        await deleteObject(imageRef);
      }
    }

    const uploadImage = (imageName: string) => new Promise((resolve, reject) => {
      // Inizia l'upload del file verso lo storage
      const firebaseApp = getApp();
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage);
      const uploadRef = ref(storageRef, imageName);
      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          switch (snapshot.state) {
            case 'paused': // or 'paused'
              console.log('Upload is paused'); // eslint-disable-line no-console
              break;
            case 'running': { // or 'running'
              // console.log('Upload is running'); // eslint-disable-line no-console
              const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${uploadProgress}% done`); // eslint-disable-line no-console
              break;
            }
            default:
          }
        }, (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // state.importErrors.push(error.code);
              break;

            case 'storage/canceled':
              // User canceled the upload
              // state.importErrors.push(error.code);
              break;

            case 'storage/unknown':
            default:
              // Unknown error occurred, inspect error.serverResponse
              // state.importErrors.push(error.code);
              break;
          }

          return reject(error);
        }, () => {
          console.log('Upload complete'); // eslint-disable-line no-console

          return getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => resolve(downloadURL),
          );
        });
    });

    // Prepara il nome del file della nuova immagine
    const imageName = `images/books/${uuidv4()}-${file.name}`;

    // Carica l'immagine nello storage
    const imageUrl = await uploadImage(imageName);

    // eslint-disable-next-line no-console
    console.log('URL dell\'immagine:', imageUrl);

    // Memorizza il riferimento all'immagine nel prodotto
    if (bookId) {
      const docRef = doc(db, `/books/${bookId}`);

      await updateDoc(docRef, {
        imageUrl,
        imageName,
      });
    }

    return Promise.resolve({
      imageUrl,
      imageName,
    });
  }

  @Action
  async removeBookImage({ bookId }: { bookId: string }) {
    console.log('actions:removeBookImage'); // eslint-disable-line no-console

    // Recupera il riferimento al prodotto
    const db = getFirestore();
    const docRef = doc(db, `/books/${bookId}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const bookDoc = await getDoc(docRef);
    const docData = bookDoc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove i riferimenti all'immagine nel prodotto
    await updateDoc(docRef, {
      imageUrl: null,
      imageName: null,
    });

    // Rimuove il file dallo storage
    if (imageName) {
      const firebaseApp = getApp();
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage);
      const imageRef = ref(storageRef, imageName);
      await deleteObject(imageRef);
    }

    return Promise.resolve();
  }
}
