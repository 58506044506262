
































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { User } from '@/types';

@Component
export default class DeleteUserDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly user!: User | null

  dialog = false
  loading = false

  @Watch('value')
  onValueChanged(val: boolean) {
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  cancel() {
    this.dialog = false;
  }

  async deleteItem() {
    if (this.user && this.user.id) {
      this.loading = true;

      await this.$store.dispatch('deleteUser', { user: this.user });

      this.loading = false;
      this.dialog = false;
    }
  }
}
