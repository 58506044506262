



















/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component,
} from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import PageHeader from '@/components/PageHeader.vue';
import Users from '@/components/Users.vue';
import NewUserDialog from '@/components/NewUserDialog.vue';

@Component({
  components: {
    Loading,
    PageHeader,
    Users,
    NewUserDialog,
  },
})
export default class Utenti extends Vue {
  loading = true
  dialog = false

  breadcrumbs = [
    {
      text: 'Utenti',
      exact: true,
      to: { name: 'Utenti' },
    },
  ]
}
