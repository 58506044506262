




















































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Card } from '@/types';

@Component
export default class EditCardDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly card!: Card | null

  dialog = false
  valid = false
  loading = false

  updatedCard = {
    id: '',
    name: '',
    description: '',
  } as Card

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false && this.card) {
      this.updatedCard.id = this.card.id;
      this.updatedCard.name = this.card.name;
      this.updatedCard.description = this.card.description;
    }
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  get isbnRules() {
    return [
      (v: string) => !!v || 'L\'ISBN è richiesto',
      (v: string) => v.length === 13 || 'L\'ISBN deve essere di 13 cifre',
    ];
  }

  onCancel() {
    this.dialog = false;
  }

  async onUpdate() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate() && this.card) {
      this.loading = true;

      await this.$store.dispatch('updateCard', {
        card: {
          id: this.card.id,
          ...this.updatedCard,
        },
      });

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
