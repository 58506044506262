import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/auth';
import Users from './modules/users';
import Books from './modules/books';
import Cards from './modules/cards';
import Artists from './modules/artists';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },

  modules: {
    auth: Auth,
    users: Users,
    books: Books,
    cards: Cards,
    artists: Artists,
  },
});
