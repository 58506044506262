













































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Artist } from '@/types';

@Component
export default class NewArtistDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean

  dialog = false
  valid = false
  loading = false

  artist = {
    name: '',
  } as Artist

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false) {
      this.artist.name = '';
    }
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  onCancel() {
    this.dialog = false;
  }

  async onAdd() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate()) {
      this.loading = true;

      await this.$store.dispatch('addArtist', {
        artist: this.artist,
      });

      this.loading = false;
      this.dialog = false;
    }
  }
}
