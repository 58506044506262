/* eslint-disable no-console */

import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

let app: Vue;

let firebaseConfig: Record<string, string>;

switch (process.env.NODE_ENV) {
  // case 'staging':
  //   firebaseConfig = {
  //     apiKey: '',
  //     authDomain: '',
  //     databaseURL: '',
  //     projectId: '',
  //     storageBucket: '',
  //     messagingSenderId: '',
  //     appId: '',
  //     // measurementId: '',
  //   };
  //   break;

  case 'production':
    firebaseConfig = {
      apiKey: 'AIzaSyBt1qgnMh2GHtC_FYYlYzklAKuTMpCS0no',
      authDomain: 'librill-prod.firebaseapp.com',
      databaseURL: 'https://librill-prod.firebaseio.com',
      projectId: 'librill-prod',
      storageBucket: 'librill-prod.appspot.com',
      messagingSenderId: '1095039444314',
      appId: '1:1095039444314:web:fc6c197d8768a81d663f63',
      measurementId: 'G-9FL2KEZ17S',
    };
    break;

  default:
    // Di default utilizza la configurazione per l'ambiente 'development'
    firebaseConfig = {
      apiKey: 'AIzaSyCri0WWGWFj6_1oihGUBFqf0RMw8v39fFM',
      authDomain: 'librill-dev.firebaseapp.com',
      databaseURL: 'https://librill-dev.firebaseio.com',
      projectId: 'librill-dev',
      storageBucket: 'librill-dev.appspot.com',
      messagingSenderId: '36762230582',
      appId: '1:36762230582:web:90bf42100011acdecbdd97',
      // measurementId: '',
    };
    break;
}

function initFirebase() {
  initializeApp(firebaseConfig);

  // eslint-disable-next-line no-prototype-builtins
  if (firebaseConfig && firebaseConfig.hasOwnProperty('measurementId')) {
    const analytics = getAnalytics();

    Vue.prototype.$analytics = analytics;
  }

  return Promise.resolve();

  // return new Promise((resolve, reject) => {
  //   firebase.firestore().enablePersistence()
  //     .then(resolve)
  //     .catch((err) => {
  //       console.log('firebase.firestore().enablePersistence() error');
  //       console.log(err);

  //       if (err.code === 'failed-precondition') {
  //         reject(err);
  //         // Multiple tabs open, persistence can only be
  //         // enabled in one tab at a a time.
  //       } else if (err.code === 'unimplemented') {
  //         reject(err);
  //         // The current browser does not support all of
  //         // the features required to enable persistence
  //       }
  //     });
  // });
}

// initFirebase
initFirebase()
  .catch((err) => {
    console.log('initFirebase() error');
    console.log(err);
  });

const auth = getAuth();

onAuthStateChanged(auth, async (user) => {
  console.log('onAuthStateChanged()');
  console.log('user =', user);

  store.dispatch('fetchUser', user);

  if (user) {
    await store.dispatch('initUsers');
    await store.dispatch('initBooks');
    await store.dispatch('initCards');
    await store.dispatch('initArtists');
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
