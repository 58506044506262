
































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Card } from '@/types';

@Component
export default class DeleteCardDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly card!: Card | null

  dialog = false
  loading = false

  @Watch('value')
  onValueChanged(val: boolean) {
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  cancel() {
    this.dialog = false;
  }

  async deleteItem() {
    if (this.card && this.card.id) {
      this.loading = true;

      await this.$store.dispatch('deleteCard', { card: this.card });

      this.loading = false;
      this.dialog = false;
    }
  }
}
