






























































import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { Book } from '@/types';

@Component
export default class BookImage extends Vue {
  @Prop(Object) readonly value: Book | null | undefined

  updating = false

  book = null as Book|null

  @Watch('book')
  onBookChanged(val: Book | null) {
    console.log('BookImage: watch.book()'); // eslint-disable-line no-console
    this.$emit('input', val);
  }

  @Watch('value')
  onValueChanged(val: Book | null) {
    if (val !== undefined) {
      this.book = val;
    }
  }

  created() {
    console.log('BookImage: created()'); // eslint-disable-line no-console
    if (this.value !== undefined) {
      this.book = this.value;
    }
  }

  get hasImage() {
    return this.book && 'imageUrl' in this.book && this.book.imageUrl !== null;
  }

  async remove() {
    this.updating = true;

    // Se stiamo modificando un prodotto esistente...
    if (this.book && this.book.id) {
      // ... elimina l'immagine anche da Firebase
      await this.$store.dispatch('removeBookImage', {
        bookId: this.book.id,
      });
    }

    this.$set(this.book as Book, 'imageUrl', null);
    this.$set(this.book as Book, 'imageName', null);

    const fileInput = this.$refs.fileInput as HTMLInputElement;

    fileInput.value = '';

    this.updating = false;
  }

  upload() {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput.click();
  }

  async handleFileChange(evt: InputEvent) {
    if (!this.book || !evt || !evt.target) return;

    const target = evt.target as HTMLInputElement;

    if (!target.files) return;

    console.log('evt.target.files =', target.files); // eslint-disable-line no-console

    if (target.files.length === 1) {
      this.updating = true;

      const { imageUrl, imageName } = await this.$store.dispatch('updateBookImage', {
        bookId: this.book.id,
        file: target.files[0],
      });

      if (imageUrl && imageName) {
        this.$set(this.book as Book, 'imageUrl', imageUrl);
        this.$set(this.book as Book, 'imageName', imageName);
      }

      this.updating = false;
    }
  }
}
