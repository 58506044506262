

























































































































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Book } from '@/types';
import BookImage from '@/components/BookImage.vue';

@Component({
  components: {
    BookImage,
  },
})
export default class EditBookDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Object, default: null }) readonly book!: Book | null

  dialog = false
  valid = false
  loading = false
  tab = null as number | null

  updatedBook = {
    id: '',
    isbn: '',
    title: '',
    author: '',
    publisher: '',
    pages: '',
    releaseDate: '',
    synopsis: '',
    imageName: null,
    imageUrl: null,
  } as Book

  @Watch('value')
  onValueChanged(val: boolean, oldVal: boolean) {
    if (val === true && oldVal === false && this.book) {
      this.updatedBook.id = this.book.id;
      this.updatedBook.isbn = this.book.isbn;
      this.updatedBook.title = this.book.title;
      this.updatedBook.author = this.book.author;
      this.updatedBook.publisher = this.book.publisher;
      this.updatedBook.pages = this.book.pages;
      this.updatedBook.releaseDate = this.book.releaseDate;
      this.updatedBook.synopsis = this.book.synopsis;
      this.updatedBook.imageName = this.book.imageName || null;
      this.updatedBook.imageUrl = this.book.imageUrl || null;
    }
    this.tab = 0;
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    if (this.value !== val) {
      this.$emit('input', val);
    }
  }

  get isbnRules() {
    return [
      (v: string) => !!v || 'L\'ISBN è richiesto',
      (v: string) => v.length === 13 || 'L\'ISBN deve essere di 13 cifre',
    ];
  }

  onCancel() {
    this.dialog = false;
  }

  async onUpdate() {
    const formRef = this.$refs.form;

    if ((formRef as any).validate() && this.book) {
      this.loading = true;

      await this.$store.dispatch('updateBook', {
        book: {
          id: this.book.id,
          ...this.updatedBook,
        },
      });

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
